import { auth, firestoreDb, handleDoc } from '@/lib/firebase';
import api from '@/lib/api';
import { titleCased } from '@/utils';
import { collections, docs } from '@/constants/collections';

export const MUTATIONS = {
  HABITAT_PASSES: 'SET_HABITAT_PASSES',
  SPECIALIZATIONS: 'SET_SPECIALIZATIONS',
  SYSTEM: 'SET_SYSTEM',
  VISIT: 'SET_VISIT',
};

export const GETTERS = {
  HABITAT_PASSES: 'GET_HABITAT_PASSES',
  SPECIALIZATIONS: 'GET_SPECIALIZATIONS',
  SYSTEM: 'GET_SYSTEM',
  VISIT: 'GET_VISIT',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  habitatPasses: [],
  specializations: [],
  system: {},
  visit: {},
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.HABITAT_PASSES](state, passes) {
    state.habitatPasses = passes;
  },
  [MUTATIONS.SPECIALIZATIONS](state, specializations) {
    state.specializations = specializations;
  },
  [MUTATIONS.SYSTEM](state, system) {
    state.system = system;
  },
  [MUTATIONS.VISIT](state, visit) {
    state.visit = visit;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.HABITAT_PASSES](state) {
    return state.habitatPasses;
  },
  [GETTERS.SPECIALIZATIONS](state) {
    return state.specializations;
  },
  [GETTERS.SYSTEM](state) {
    return state.system;
  },
  [GETTERS.VISIT](state) {
    return state.visit;
  },
}

const sortedSpecs = (all) => {
  return all.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1)
};

export const actions = {
  async saveHabitatPasses({ commit }, passes) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/settings/habitat-passes',
        { passes },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      const sorted = response.data.sort((a, b) => a.level - b.level);

      commit(MUTATIONS.HABITAT_PASSES, sorted);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async loadHabitatPasses({ commit }) {
    const docs = await firestoreDb.collection('habitat-pass').get();
    const passes = docs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const sorted = passes.sort((a, b) => a.level - b.level);
    commit(MUTATIONS.HABITAT_PASSES, sorted);
  },

  async loadSpecializations({ commit }) {
    const doc = await firestoreDb.collection('settings').doc('specializations').get();
    const all = sortedSpecs(doc.data().all || []);

    all.forEach((spec, index) => {
      all[index].title = titleCased(spec.title)
    });

    commit(MUTATIONS.SPECIALIZATIONS, all);
  },

  async loadVisit({ commit }) {
    const visitSettings = await firestoreDb
      .collection(collections.settings)
      .doc(docs.visit)
      .get().then(handleDoc)

    commit(MUTATIONS.VISIT, visitSettings);
  },

  async loadSystem({ rootGetters }) {
    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

    if (!isAdminOrSuperAdmin) {
      throw 'error.user.not.unauthorized';
    }

    const doc = await firestoreDb.collection('settings').doc('system').get();
    return doc.data();
  },

  async loadPublicSystem({ commit }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/settings/system',
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = response.data;

      commit(MUTATIONS.SYSTEM, { ...data });

      return data;
    } catch(error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async saveSystem({ dispatch }, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/settings/system',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      dispatch('settings/loadPublicSystem', null, { root: true });

      return response.data;
    } catch(error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async saveSaas(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/settings/saas',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch(error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async saveHolidays(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/settings/holidays',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch(error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async copyHolidays(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/settings/holidays/copy',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch(error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  saveSpecialization({ commit, rootGetters }, { spec }) {
    return new Promise((resolve, reject) => {
      const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

      if (!isAdminOrSuperAdmin) {
        return reject('error.user.not.unauthorized');
      }

      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/settings/specializations',
          { spec },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const all = sortedSpecs(response.data);
          commit(MUTATIONS.SPECIALIZATIONS, all);
          resolve(all);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  },

  deleteSpecialization({ commit, rootGetters }, { spec }) {
    return new Promise((resolve, reject) => {
      const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

      if (!isAdminOrSuperAdmin) {
        return reject('error.user.not.unauthorized');
      }

      auth.currentUser.getIdToken().then((token) => {
        api.delete(
          '/settings/specializations',
          { 
            params: { id: spec.id },
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then((response) => {
          const all = sortedSpecs(response.data);
          commit(MUTATIONS.SPECIALIZATIONS, all);
          resolve(all);
        })
        .catch((err) => reject(err.response && err.response.data || err));
      })
      .catch((err) => reject(err.response && err.response.data || err));
    });
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
