import Vue from 'vue';
import router from '@/router';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import api from '@/lib/api';
import Analytics from '@/lib/analytics';
import events from '@/constants/events';
import {
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  signInWithCredential,
  signOut,
} from 'firebase/auth';
import { IS_APP } from '@/constants/index';
import { auth, firebase, firestoreDb, handleDoc } from '@/lib/firebase';
import { HABITAT_HELP_UID } from '@/constants/chatConstants.js';
import { notify } from '@/lib/notifications';
import { collections, docs } from '@/constants/collections';
import { uploadImage } from '@/utils/storage';
import locale from '../../locale';

const GET_USER_UID = 'GET_USER_UID';
const GET_USER_INFO = 'GET_USER_INFO';
const GET_IS_LOGGING_IN = 'GET_IS_LOGGING_IN';
const GET_IS_SIGNING_UP = 'GET_IS_SIGNING_UP';
const GET_IS_UPDATING = 'GET_IS_UPDATING';
const GET_IS_FETCHING = 'GET_IS_FETCHING';
const GET_IS_DOCTOR_VALIDATED = 'GET_IS_DOCTOR_VALIDATED';
const GET_PREFERENCES = 'GET_PREFERENCES';
const GET_LAST_ACCEPTED_TERM = 'GET_LAST_ACCEPTED_TERM';
const GET_READONLY_UNSUBSCRIBER = 'GET_READONLY_UNSUBSCRIBER';
const GET_PENDING_DATA_REQUESTS_UNSUBSCRIBER = 'GET_PENDING_DATA_REQUESTS_UNSUBSCRIBER';
const GET_HAS_PENDING_DATA_REQUESTS = 'GET_HAS_PENDING_DATA_REQUESTS';

const SET_IS_SIGNING_UP = 'SET_IS_SIGNING_UP';
const SET_IS_UPDATING = 'SET_IS_UPDATING';
const SET_IS_FETCHING = 'SET_IS_FETCHING';
const SET_IS_LOGGING_IN = 'SET_IS_LOGGING_IN';
const SET_LAST_ACCEPTED_TERM = 'SET_LAST_ACCEPTED_TERM';
const SET_DOCS = 'SET_DOCS';
const SET_READONLY_INFO = 'SET_READONLY_INFO';
const SET_USER_INFO = 'SET_USER_INFO';
const SET_PREFERENCES = 'SET_PREFERENCES';
const SET_USER = 'SET_USER';
const SET_READONLY_UNSUBSCRIBER = 'SET_READONLY_UNSUBSCRIBER';
const SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER = 'SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER';
const SET_HAS_PENDING_DATA_REQUESTS = 'SET_HAS_PENDING_DATA_REQUESTS';

const RESET = 'RESET';

const getDefaultState = () => {
  return {
    // isSyncing: false,
    isLoggingIn: false,
    isSigningUp: false,
    isUpdating: false,
    pendingDataRequests: false,
    user: null,
    userInfo: null,
    userDocs: [],
    preferences: null,
    isFetching: false,
    readonlyInfo: null,
    chatUnsubscribers: null,
    pendingDataRequestUnsubscriber: null,
    readonlyUnsubscriber: null,
    unreadCountsPerKey: {},
  };
};

export const state = getDefaultState();

export const mutations = {
  [SET_USER](state, { user }) {
    state.user = user
  },
  [SET_USER_INFO](state, { userInfo }) {
    state.userInfo = userInfo
  },
  [SET_IS_LOGGING_IN](state, isLoggingIn) {
    state.isLoggingIn = isLoggingIn
  },
  [SET_IS_SIGNING_UP](state, isSigningUp) {
    state.isSigningUp = isSigningUp
  },
  [SET_IS_UPDATING](state, isUpdating) {
    state.isUpdating = isUpdating
  },
  [SET_IS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },
  [SET_READONLY_INFO](state, { readonlyInfo }) {
    state.readonlyInfo = readonlyInfo
  },
  [SET_READONLY_UNSUBSCRIBER](state, readonlyUnsubscriber) {
    state.readonlyUnsubscriber = readonlyUnsubscriber
  },
  SET_CHAT_UNSUBSCRIBERS(state, { chatUnsubscribers }) {
    state.chatUnsubscribers = chatUnsubscribers
  },
  [SET_DOCS](state, docs) {
    state.userDocs = docs;
  },
  SET_UNREAD_COUNTS_PER_KEY(state, { key, unreadCounts }) {
    Vue.set(state.unreadCountsPerKey, key, unreadCounts)
  },
  [SET_LAST_ACCEPTED_TERM](state, term) {
    state.lastAcceptedTerm = term;
  },
  [SET_PREFERENCES](state, preferences) {
    state.preferences = preferences;
  },
  [SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER](state, unsubscriber) {
    state.pendingDataRequestUnsubscriber = unsubscriber;
  },
  [SET_HAS_PENDING_DATA_REQUESTS] (state, pendingDataRequests) {
    state.pendingDataRequests = pendingDataRequests;
  },

  [RESET](state) {
    if (state.chatUnsubscribers) {
      state.chatUnsubscribers.forEach((u) => u());
    }

    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_IS_LOGGING_IN](state) {
    return state.isLoggingIn;
  },
  [GET_IS_SIGNING_UP](state) {
    return state.isSigningUp;
  },
  GET_USER(state) {
    return state.user;
  },
  [GET_USER_INFO](state) {
    return state.userInfo;
  },
  [GET_USER_UID](state) {
    return state.user ? state.user.uid : null;
  },
  IS_CONNECTED(state) {
    return !!state.user;
  },
  // IS_CONNECTED_AS_DOCTOR(state) {
  //   return !!state.user && !!state.userInfo && state.userInfo.roles.includes('doctor')
  // },
  // IS_CONNECTED_AS_OWNER(state) {
  //   return !!state.user && !!state.userInfo && state.userInfo.roles.includes('owner')
  // },
  IS_CONNECTED_AND_VERIFIED(state) {
    return !!state.user && state.user.emailVerified
  },
  [GET_IS_UPDATING](state) {
    return state.isUpdating;
  },
  GET_READONLY_INFO(state) {
    return state.readonlyInfo;
  },
  [GET_IS_FETCHING](state) {
    return state.isFetching;
  },
  GET_IS_ADMIN_OR_SUPER_ADMIN(_, getters) {
    const readonlyInfo = getters.GET_READONLY_INFO;
    return readonlyInfo && (readonlyInfo.isAdmin || readonlyInfo.isSuperAdmin)
  },
  [GET_IS_DOCTOR_VALIDATED](_, getters) {
    const readonlyInfo = getters.GET_READONLY_INFO;
    return readonlyInfo && readonlyInfo.isDoctorValidated;
  },
  [GET_READONLY_UNSUBSCRIBER](state) {
    return state.readonlyUnsubscriber;
  },
  GET_CHAT_UNSUBSCRIBERS(state) {
    return state.chatUnsubscribers;
  },
  GET_UNREAD_COUNTS_PER_KEY(state) {
    return state.unreadCountsPerKey;
  },
  [GET_PREFERENCES](state) {
    return state.preferences;
  },
  [GET_LAST_ACCEPTED_TERM](state) {
    return state.lastAcceptedTerm;
  },
  [GET_PENDING_DATA_REQUESTS_UNSUBSCRIBER](state) {
    return state.pendingDataRequestUnsubscriber;
  },
  [GET_HAS_PENDING_DATA_REQUESTS](state) {
    return state.pendingDataRequests;
  },
}

const afterLogin = async (options) => {
  const {
    commit,
    dispatch,
    getters,
    user,
    noRedirect = false,
    isSaasSignUp = false,
  } = options;
  const userInfo = await firestoreDb
    .collection(collections.users)
    .doc(user.uid)
    .get()
    .then(handleDoc);

  dispatch('setInitialData', { user, userInfo });

  try {
    await dispatch('fetchReadonlyInfo');

    const bypass = router.currentRoute?.name == 'schedule-visit';

    if (bypass || user.emailVerified) {
      const readonlyInfo = getters.GET_READONLY_INFO;
      const mustSetPassword = readonlyInfo?.mustSetPassword ?? false;
      const isFirstLogin = userInfo?.isFirstLogin ?? false;

      Vue.toasted.success(isFirstLogin ? 'Bem-vindo!' : 'Bem-vindo de volta!')
      Analytics.logEvent(events.login, { email: user.email });

      const useDoctorDashboard = userInfo?.preferences?.useDoctorDashboard || false;
      const isAdminOrSuperAdmin = getters.GET_IS_ADMIN_OR_SUPER_ADMIN;

      let defaultRoute;

      if (mustSetPassword) {
        defaultRoute = '/set-password';
      } else if (isFirstLogin) {
        defaultRoute = '/profile';
      } else {
        const isSaas = !!readonlyInfo?.saasId || !!readonlyInfo?.ownerSaasId;
        defaultRoute = '/owner-dashboard';


        if (IS_APP || useDoctorDashboard) {
          defaultRoute = '/doctor/dashboard';
        } else if (isSaas) {
          if (readonlyInfo?.isSaasActive || readonlyInfo?.saasRole === 'receptionist') {
            defaultRoute = '/owner/saas/dashboard';
          } else {
            defaultRoute = '/owner/saas/subscription';
          }
        }
      }

      if (isFirstLogin && user.emailVerified) {
        dispatch('save', {
          payload: {
            isFirstLogin: false,
          },
        });
      }

      if (noRedirect) {
        return;
      }

      // redirection
      await router.push(
        router.currentRoute.query && router.currentRoute.query.redirectTo
        ? router.currentRoute.query.redirectTo
        : isAdminOrSuperAdmin ? 'admin-dashboard' : defaultRoute
      );
    } else if (!isSaasSignUp) {
      Vue.toasted.global.custom_error({ message: locale.t('error.emailVerificationRequired') })
      await router.push({ name: 'email-verification', query: { email: user.email } });
    }

    await dispatch('fetchAcceptedTermsInfo');
  } catch (error) {
    console.error(error);

    if (error === 'error.saas.inactive') {
      Vue.toasted.global.custom_error({ message: locale.t(error) })
      await dispatch('signout');
    } else if (error === 'error.not.saas.user') {
      await router.push({ name: 'saas-access-request', query: { email: user.email } });
    } else if (error === 'error.saas.user.not.found') {
      Vue.toasted.global.custom_error({ message: locale.t(error) })
      await dispatch('signout');
    }
  } finally {
    commit(SET_IS_LOGGING_IN, false);
  }
};

export const actions = {
  async loginWithUserToken({ commit, dispatch, getters }, token) {
    const result = await FirebaseAuthentication.signInWithCustomToken({ token });
    afterLogin({
      commit,
      dispatch,
      getters,
      user: result.user,
    });
  },

  async login({ commit, dispatch, getters }, payload) {
    commit(SET_IS_LOGGING_IN, true);

    try {
      const { email, password } = payload;
      // 1. Native layer
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
      // 2. Web layer
      const credential = EmailAuthProvider.credential(email, password);
      const auth = getAuth();

      await signInWithCredential(auth, credential);
      await afterLogin({
        commit,
        dispatch,
        getters,
        user: result.user,
        noRedirect: payload.noRedirect,
      });

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = locale.t(errorCode);

      throw new Error(errorMessage);
    } finally {
      commit(SET_IS_LOGGING_IN, false)
    }
  },

  async providerLogin({ commit, dispatch, getters }, payload) {
    const { provider, attStatus } = payload;

    commit(SET_IS_LOGGING_IN, true);

    if (provider === 'google') {
      try {
        // 1. Native layer
        const result = await FirebaseAuthentication.signInWithGoogle();
        // 2. Link on the web layer using the id token
        const credential = GoogleAuthProvider.credential(result.credential?.idToken);
        const user = result.user
        const auth = getAuth();
        await signInWithCredential(auth, credential);

        await api.post('/user/signup/provider', {
          registeredId: user.uid,
          provider: 'google',
          email: user.email,
          name: user.displayName,
          attStatus,
        });

        await afterLogin({
          commit,
          dispatch,
          getters,
          user,
          noRedirect: payload.noRedirect,
        });
      } catch (error) {
        commit(SET_IS_LOGGING_IN, false);

        const errorCode = error.code;
        const errorMessage = locale.t(errorCode);

        throw new Error(errorMessage)
      }
    } else if (provider === 'apple') {
      try {
        // https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md#quirks
        // 1. Native layer
        const result = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true,
          scopes: ['name', 'email'],
        });
        const provider = new OAuthProvider('apple.com', {
          scopes: ['name', 'email']
        });
        // 2. Link on the web layer using the id token
        const credential = provider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce,
        });
        const auth = getAuth();
        const credentialResult = await signInWithCredential(auth, credential);
        const user = credentialResult.user;
        const providerData = user.providerData[0];

        await api.post('/user/signup/provider', {
          registeredId: user.uid,
          provider: 'apple',
          email: user.email || providerData?.email,
          name: user.displayName || providerData?.displayName || result?.user?.displayName,
          attStatus,
        });

        await afterLogin({ commit, dispatch, getters, user });
      } catch (error) {
        commit(SET_IS_LOGGING_IN, false);

        const errorCode = error.code;
        const errorMessage = locale.t(errorCode || 'error.defaultMessage');

        throw new Error(errorMessage)
      }
    } else {
      commit(SET_IS_LOGGING_IN, false);
      throw new Error('error.defaultMessage');
    }
  },

  async signup({ commit, dispatch, getters }, payload) {
    commit(SET_IS_SIGNING_UP, true);
    const { email, password } = payload;

    try {
      const registeredUser = await FirebaseAuthentication.createUserWithEmailAndPassword({ email, password });

      await api.post('/user/signup', {
        registeredId: registeredUser.user.uid,
        ...payload,
      });

      if (payload.noRedirect) {
        await afterLogin({
          commit,
          dispatch,
          getters,
          user: registeredUser.user,
          noRedirect: true,
          isSaasSignUp: payload.isSaasSignUp,
        });
        return;
      }
      if (!payload.isSaasSignUp) {
        router.push({ name: 'email-verification', query: { email } });
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = locale.t(errorCode);

      throw new Error(errorMessage);
    } finally {
      commit(SET_IS_SIGNING_UP, false);
    }
  },

  async userVerification(_, { verificationCode }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post('/user/code-verification', 
      { verificationCode },
      { headers: { Authorization: `Bearer ${token}` } },
    );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async signout({ commit }, redirect = true) {
    try {
      // 1. Sign out on the native layer
      await FirebaseAuthentication.signOut();
      // 2. Sign out on the web layer
      const auth = getAuth();
      await signOut(auth);

      if (redirect) {
        if (!router.currentRoute || router.currentRoute.name !== 'home') {
          router.push({ path: '/' });
        }
      }

      commit(SET_USER, { user: null });
      commit(SET_USER_INFO, { userInfo: null });
      commit(SET_PREFERENCES, null);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = locale.t(errorCode);
      throw new Error(errorMessage);
    }
  },

  async refreshInitialData({ dispatch }) {
    const auth = getAuth();

    await auth.currentUser.reload();

    const user = auth.currentUser;
    const userInfo = await firestoreDb
      .collection(collections.users)
      .doc(user.uid)
      .get()
      .then(handleDoc);

    dispatch('setInitialData', { user, userInfo });
  },

  setInitialData({ commit }, { user, userInfo }) {
    commit(SET_USER, { user });
    commit(SET_USER_INFO, { userInfo });
    commit(SET_PREFERENCES, userInfo?.preferences || {});
  },

  async resetPassword(_, payload) {
    const { email } = payload;

    try {
      await FirebaseAuthentication.sendPasswordResetEmail({ email });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = errorCode === 'auth/user-not-found' ? 'error.user.email.not.found' : error.message;

      throw new Error(errorMessage);
    }
  },

  async updatePassword({ dispatch }, { oldPassword, newPassword }) {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Revalidate once to check old password
      let credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      // Then update password
      const token = await auth.currentUser.getIdToken();

      await api.post('/user/update-password',
        { password: newPassword },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      // Revalidate again with new password
      credential = EmailAuthProvider.credential(user.email, newPassword);

      await reauthenticateWithCredential(user, credential);
      await Promise.all([
        dispatch('refreshInitialData'),
        dispatch('fetchReadonlyInfo'),
      ])
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = locale.t(errorCode);

      throw new Error(errorMessage);
    }
  },

  async updateEmail({ dispatch }, { email }) {
    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/update-email',
        { email },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      await dispatch('signout');
    } catch (err) {
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async removeAccount({ dispatch }) {
    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/remove-account',
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      await dispatch('signout');
    } catch (err) {
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async sendEmailVerification(_, email) {
    const user = auth.currentUser;

    return api.post('/user/resend-verification-email', { id: user?.uid, email });
  },

  async save({ getters, commit }, { userId, payload = {}, profileImageFile }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const uid = getters.GET_USER_UID;

      if (!uid) {
        throw new Error('error.user.not.connected');
      }

      if (userId && userId !== uid && !getters.GET_IS_ADMIN_OR_SUPER_ADMIN) {
        throw new Error('error.user.not.unathorized');
      }

      const userInfoModified = { ...payload };

      commit(SET_IS_UPDATING, true);

      if (profileImageFile) {
        const url = await uploadImage({
          file: profileImageFile,
          path: `user/${ userId || uid }/profileImage`,
        });

        userInfoModified.profileImageUrl = url;
      }

      await api.put(
        '/user',
        {
          ...userInfoModified,
          userId,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (userId && userId !== uid) {
        return;
      }

      await firestoreDb
        .collection(collections.users)
        .doc(uid)
        .get()
        .then(handleDoc)
        .then((userInfo) => {
          commit(SET_USER_INFO, { userInfo });
          commit(SET_IS_UPDATING, false);
        });
    } catch (error) {
      throw error.response?.data || error;
    } finally {
      commit(SET_IS_UPDATING, false);
    }
  },

  async fcmTokenUpdated(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/user/fmc-token',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async surveyShown() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/user/survey-shown',
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async updatePreferences({ getters, commit }, {payload, save}) {
    const uid = getters.GET_USER_UID;

    if (!uid) {
      throw new Error('error.user.not.found');
    }

    commit(SET_IS_UPDATING, true);

    const preferences = getters.GET_PREFERENCES ?? {};
    const updated = {
      ...preferences,
      ...payload,
    };

    try {
      if (save) {
        const prevUserInfo = getters.GET_USER_INFO;
        await firestoreDb.collection(collections.users).doc(uid).update({
          preferences: updated,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        commit(SET_USER_INFO, {
          userInfo: {
            ...prevUserInfo,
            preferences: updated,
          },
        });
      }

      commit(SET_PREFERENCES, updated);
    } catch (error) {
      throw error.response?.data || error;
    } finally {
      commit(SET_IS_UPDATING, false);
    }
  },

  docs({ commit }, { files, filenames }) {
    return new Promise((resolve, reject) => {
      commit(SET_IS_UPDATING, true);
      auth.currentUser.getIdToken().then((token) => {
        const formData = new FormData();
        files.forEach((f) => formData.append('files[]', f))
        formData.append('filenames', JSON.stringify(filenames));

        api.post(
          '/user/docs',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        )
        .then((response) => {
          const docs = response.data;
          commit(SET_DOCS, docs);
          resolve(docs);
        })
        .catch(reject)
        .finally(() => commit(SET_IS_UPDATING, false));
      })
      .catch((err) => {
        commit(SET_IS_UPDATING, false)
        reject(err)
      });
    });
  },

  fetchDocs({ getters, commit }) {
    return new Promise((resolve, reject) => {
      const uid = getters.GET_USER_UID
      if (!uid) {
        reject('error.user.not.connected')
        return
      }

      commit(SET_IS_FETCHING, true);

      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/user/docs',
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((response) => {
          commit(SET_DOCS, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          commit(SET_IS_FETCHING, false)
          reject(error);
        });
      })
      .catch((error) => {
        commit(SET_IS_FETCHING, false)
        reject(error);
      });
    })
  },

  async fetchReadonlyInfo({ commit }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/readonly-info', {
        headers: { Authorization: `Bearer ${token}` },
      });

      commit(SET_READONLY_INFO, {
        readonlyInfo: response.data,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchAcceptedTermsInfo({ getters, commit }) {
    const uid = getters.GET_USER_UID;

    if (!uid) {
      throw 'error.user.not.connected';
    }

    try {
      const terms = await firestoreDb
        .collection('users')
        .doc(uid)
        .collection('terms-of-use')
        .doc('info')
        .get()
        .then(handleDoc);

      commit(SET_LAST_ACCEPTED_TERM, terms?.last ?? {});
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUserInfo(_, userId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user', {
        params: { id: userId },
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      console.log(error)

      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchDoctorPatients() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/patients/names', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async createPatient(_, { name }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post('/user/patients',
        { name },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchPatientsInfo(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/patients',
        { 
          params: payload,
          headers: { Authorization: `Bearer ${token}` } 
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOwnerUserInfo(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/owner/user/info', {
        params: payload,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchMyUnities(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/unities', {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  loadChatCounts({ getters, commit }) {
    // reset previous unsubscribers if any
    const prevChatUnsubscribers = getters.GET_CHAT_UNSUBSCRIBERS
    if (prevChatUnsubscribers && prevChatUnsubscribers.length > 0) {
      for (const prevChatUnsubscriber of prevChatUnsubscribers) {
        prevChatUnsubscriber()
      }
      commit('SET_CHAT_UNSUBSCRIBERS', { chatUnsubscribers: null })
    }

    const myId = getters.GET_USER_UID
    const isAdminOrSuperAdmin = getters.GET_IS_ADMIN_OR_SUPER_ADMIN

    let ownerQuery = firestoreDb.collection('chats')
      .where('userIds', 'array-contains', myId)
      .where('ownerId', '==', myId)
      .where('isHelpDesk', '==', false)
    let doctorQuery = firestoreDb.collection('chats')
      .where('userIds', 'array-contains', myId)
      .where('doctorId', '==', myId)
      .where('isHelpDesk', '==', false)
    let notAdminQuery = firestoreDb.collection('chats')
      .where('userIds', 'array-contains', myId)
      .where('isHelpDesk', '==', true)
    let adminQuery = firestoreDb.collection('chats')
      .where('userIds', 'array-contains', HABITAT_HELP_UID)
      .where('isHelpDesk', '==', true)

    const queries = [{ query: doctorQuery, key: 'doctor' }, { query: ownerQuery, key: 'owner' }, { query: notAdminQuery, key: 'helpdesk' }]
    if (isAdminOrSuperAdmin) {
      queries.push({ query: adminQuery, key: 'helpdesk-admin' })
    }

    const chatUnsubscribers = []
    for (const { query, key } of queries) {
      const unreadCountsPerKey = getters.GET_UNREAD_COUNTS_PER_KEY
      const unreadCounts = unreadCountsPerKey && unreadCountsPerKey[key] ? unreadCountsPerKey[key] : {}
      const unsubscriber = query
        .orderBy('lastMessageTimestamp', 'desc')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const newVal = { id: change.doc.id, ...change.doc.data() }
            const newCount = newVal.unreadCounts[key === 'helpdesk-admin' ? HABITAT_HELP_UID : myId]
            if (change.type === 'added') {
              unreadCounts[newVal.id] = newCount
            } else if (change.type === 'removed') {
              // this should never happen
              console.warn('[WARN] Chat removed? Please contact Habitat support.')
              unreadCounts[newVal.id] = 0
            } else if (change.type === 'modified') {
              if (unreadCounts[newVal.id] < newCount) {
                const virtualId = key === 'helpdesk-admin' ? HABITAT_HELP_UID : myId
                const otherName = newVal.userIds[0] === virtualId ? newVal.names[1] : newVal.names[0]
                notify(`${otherName}:`, newVal.lastMessage)
              }
              unreadCounts[newVal.id] = newCount
            } else {
              // should never happen
              console.warn('[WARN] Unknown change type:', change.type)
            }
          })
          commit('SET_UNREAD_COUNTS_PER_KEY', { key, unreadCounts })
        })
      chatUnsubscribers.push(unsubscriber)
    }

    commit('SET_CHAT_UNSUBSCRIBERS', { chatUnsubscribers })
  },

  async lastPurchasedSpaces({ getters }) {
    const uid = getters.GET_USER_UID;
    const doc = await firestoreDb
      .collection('users')
      .doc(uid)
      .collection('lastPurchasedSpaces')
      .doc('info')
      .get();
    return doc.exists ? doc.data() : null;
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },

  unsubscribeAll({ getters, commit }) {
    const prevChatUnsubscribers = getters.GET_CHAT_UNSUBSCRIBERS
    if (prevChatUnsubscribers && prevChatUnsubscribers.length > 0) {
      for (const prevChatUnsubscriber of prevChatUnsubscribers) {
        prevChatUnsubscriber()
      }
      commit('SET_CHAT_UNSUBSCRIBERS', { chatUnsubscribers: null })
    }

    const prevReadonlyUnsubscriber = getters.GET_READONLY_UNSUBSCRIBER;

    if (prevReadonlyUnsubscriber) {
      prevReadonlyUnsubscriber();
      commit(SET_READONLY_UNSUBSCRIBER, null);
    }

    const prevPendingDataRequestsUnsubscriber = getters.GET_PENDING_DATA_REQUESTS_UNSUBSCRIBER;

    if (prevPendingDataRequestsUnsubscriber) {
      prevPendingDataRequestsUnsubscriber();
      commit(SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER, null);
    }
  },

  async updateProfileBlocked({getters, commit}) {
    const readonlyInfo = getters.GET_READONLY_INFO;

    if (!readonlyInfo?.doctorProfileBlocked) {
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/profile-blocked',
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      commit(SET_READONLY_INFO, {
        readonlyInfo: {
          ...readonlyInfo,
          doctorProfileBlocked: false,
        },
      });
    } catch (err) {
      console.log(err)
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async updateSocialConnectionBlocked({getters, commit}) {
    const readonlyInfo = getters.GET_READONLY_INFO;

    if (!readonlyInfo?.socialConnectionBlocked) {
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/social-connection-blocked',
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      commit(SET_READONLY_INFO, {
        readonlyInfo: {
          ...readonlyInfo,
          socialConnectionBlocked: false,
        },
      });
    } catch (err) {
      console.log(err)
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async updateSpecsSeen({ getters, commit }) {
    const readonlyInfo = getters.GET_READONLY_INFO;

    if (readonlyInfo?.isSpecsSeen) {
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/specs-seen',
        null,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      commit(SET_READONLY_INFO, {
        readonlyInfo: {
          ...readonlyInfo,
          isSpecsSeen: true,
        },
      });
    } catch (err) {
      console.log(err)
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async updateATT({ getters, commit }, status) {
    const readonlyInfo = getters.GET_READONLY_INFO;

    if (readonlyInfo?.isSpecsSeen) {
      return;
    }

    try {
      const token = await auth.currentUser.getIdToken();
      await api.post('/user/att',
        { status },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      commit(SET_READONLY_INFO, {
        readonlyInfo: {
          ...readonlyInfo,
          attStatus: status,
        },
      });
    } catch (err) {
      console.log(err)
      const error = err.response.data;
      throw locale.t(error);
    }
  },

  async acceptedTerms() {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await api.get('/user/accepted-terms',
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async dataShareList() {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await api.get('/user/data-share-list',
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesRequests(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/services/requests',
        { 
          params: payload,
          headers: { Authorization: `Bearer ${token}` } 
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServices(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/user/services',
        { 
          params: payload,
          headers: { Authorization: `Bearer ${token}` } 
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  subscribeReadonly({ getters, rootGetters, commit }) {
    const userId = rootGetters['user/GET_USER_UID'];

    const prevUnsubscriber = getters[GET_READONLY_UNSUBSCRIBER];

    if (prevUnsubscriber) {
      prevUnsubscriber();
      commit(SET_READONLY_UNSUBSCRIBER, null);
    }

    const unsubscriber = firestoreDb
      .collection(collections.users)
      .doc(userId)
      .collection(collections.readonly)
      .doc(docs.readonlyInfo)
      .onSnapshot((snapshot) => {
        const readonlyInfo = snapshot.data();
        const oldReadonlyInfo = getters.GET_READONLY_INFO;

        if (readonlyInfo) {
          commit(SET_READONLY_INFO, { readonlyInfo: {
            ...oldReadonlyInfo,
            ...readonlyInfo,
          } });
        }
      });

    commit(SET_READONLY_UNSUBSCRIBER, unsubscriber);
  },

  subscribePendingDataRequest({getters, rootGetters, commit}) {
    const userId = rootGetters['user/GET_USER_UID'];

    const prevUnsubscriber = getters[GET_PENDING_DATA_REQUESTS_UNSUBSCRIBER];

    if(prevUnsubscriber) {
      prevUnsubscriber();
      commit (SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER, null);
    }

    const unsubscriber = firestoreDb
      .collectionGroup(collections.saasUsers)
      .where('status', '==', 'pending')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) => {
        commit(SET_HAS_PENDING_DATA_REQUESTS, snapshot.docs.length > 0);
      });
    commit(SET_PENDING_DATA_REQUESTS_UNSUBSCRIBER, unsubscriber);

  },

  async fetchSaasAccess() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.get('/user/request/saas/access',
        { headers:
          { Authorization: `Bearer ${token}` },
        },
      );
    }
    catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async requestSaasAccess() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post('/user/request/saas/access',
        null,
        { headers:
          { Authorization: `Bearer ${token}` },
        },
      );
    }
    catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async acceptOrRejectShareData(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post('/user/accept-or-reject-share-data',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
