import api from '@/lib/api';

import { auth } from '@/lib/firebase';

const GET_RECORDING_TIME = 'GET_RECORDING_TIME';
const GET_LAST_EXIT_TIME = 'GET_LAST_EXIT_TIME';

const SET_RECORDING_TIME = 'SET_RECORDING_TIME';
const SET_LAST_EXIT_TIME = 'SET_LAST_EXIT_TIME';

const getDefaultState = () => {
  return {
    recordingTime: 0,
    lastExitTime: null,
  }
};

export const state = getDefaultState();

export const mutations = {
  [SET_RECORDING_TIME](state, recordingTime) {
    state.recordingTime = recordingTime;
  },
  [SET_LAST_EXIT_TIME](state, time) {
    state.lastExitTime = time;
  },
}

export const getters = {
  [GET_RECORDING_TIME](state) {
    return state.recordingTime;
  },
  [GET_LAST_EXIT_TIME](state) {
    return state.lastExitTime
  }
}

export const actions = {
  async addAudio(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post('/audio',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async deleteAudio(_, { id }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.delete(`/audio/${id}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}