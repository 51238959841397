import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import multiguard from 'vue-router-multiguard'
import { useFavicon } from '@vueuse/core';

import { firestoreDb, handleDoc } from '@/lib/firebase'
import { IS_APP, DEFAULT_PAGE_TITLE } from '@/constants';
import { collections } from '@/constants/collections';
import { getATTStatus } from '@/lib/preferences';
import { isIOS } from '@/lib/app';
import Analytics from '@/lib/analytics';
import AuthService from '@/services/AuthService'
import store from '@/store'
import adminOrSuperAdminMiddleware from '@/middleware/adminOrSuperAdminMiddleware'
import authAndVerifiedMiddleware from '@/middleware/authAndVerifiedMiddleware'
import saasTeamMiddleware from '@/middleware/saasTeamMiddleware'
import superAdminMiddleware from '@/middleware/superAdminMiddleware'
import notAuthOrNotVerifiedMiddleware from '@/middleware/notAuthOrNotVerifiedMiddleware'
import events from '@/constants/events'
// import { routeChangeHubspotCallback } from '@/utils/hubspot-chat.js'

Vue.use(VueRouter);

const adminRoutes = [
  // -----------------------------------------
  // ADMIN
  // -----------------------------------------
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-dashboard" */
      '@/router/routes/admin/AdminDashboard.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-settings',
    name: 'admin-settings',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-settings" */
      '@/router/routes/admin/settings/AdminSettings.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, superAdminMiddleware]),
  },
  {
    path: '/admin-helpdesk',
    name: 'admin-helpdesk',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-helpdesk" */
      '@/router/routes/admin/AdminHelpdesk.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-spaces',
    name: 'admin-spaces',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-unities" */
      '@/router/routes/admin/AdminUnities.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users',
    name: 'admin-users',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-users" */
      '@/router/routes/admin/AdminUsers.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-coupons" */
      '@/router/routes/admin/AdminCoupons.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id',
    name: 'admin-user-details',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-details" */
      '@/router/routes/admin/users/AdminUserDetails.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/profile',
    name: 'admin-user-profile',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-profile" */
      '@/router/routes/admin/users/AdminUserProfile.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/events',
    name: 'admin-user-events',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-events" */
      '@/router/routes/admin/users/AdminUserEvents.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/owner-calendar',
    name: 'admin-user-owner-calendar',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-owner-calendar" */
      '@/router/routes/admin/users/AdminUserOwnerCalendar.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/transactions',
    name: 'admin-user-transactions',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-transactions" */
      '@/router/routes/admin/users/AdminUserTransactions.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/products',
    name: 'admin-user-products',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-products" */
      '@/router/routes/admin/users/AdminUserProducts.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/user/product',
    name: 'admin-user-product',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-product" */
      '@/router/routes/admin/users/AdminUserProduct.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/subscription',
    name: 'admin-user-subscription',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-subscription" */
      '@/router/routes/admin/users/AdminUserSubscription.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-users/:id/hour-package',
    name: 'admin-user-hour-package',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-user-hour-package" */
      '@/router/routes/admin/users/AdminUserHourPackage.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-validate-doctors',
    name: 'admin-validate-doctors',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-validate-doctors" */
      '@/router/routes/admin/AdminValidateDoctors.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-validate-spaces',
    name: 'admin-validate-spaces',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-validate-spaces" */
      '@/router/routes/admin/AdminValidateSpaces.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-transactions',
    name: 'admin-transactions',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-transactions" */
      '@/router/routes/admin/AdminTransactions.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-habitat-pass',
    name: 'admin-habitat-pass',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-habitat-pass" */
      '@/router/routes/admin/AdminHabitatPass.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-monthly-integral',
    name: 'admin-monthly-integral',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-monthly-integral" */
      '@/router/routes/admin/AdminMonthlyIntegral.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-subscriptions',
    name: 'admin-subscriptions',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-subscriptions" */
      '@/router/routes/admin/AdminSubscriptions.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin-finance',
    name: 'admin-finance',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-finance" */
      '@/router/routes/admin/finance/AdminFinance.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
    children: [
      {
        path: 'accounts',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-finance-accounts" */
          '@/router/routes/owner/finance/OwnerFinanceAccounts.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
        children: [
          {
            path: '',
            name: 'admin-finance-receivers',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receivers" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceivers.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId',
            name: 'admin-finance-receiver-details',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-details" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverDetails.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/banks',
            name: 'admin-finance-receiver-banks',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-banks" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverBanks.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/transactions',
            name: 'admin-finance-receiver-transactions',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-transactions" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverTransactions.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/history',
            name: 'admin-finance-receiver-history',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-history" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverHistory.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/transfer',
            name: 'admin-finance-receiver-transfer',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-transfer" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverTransfer.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/documents',
            name: 'admin-finance-receiver-documents',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-admin-finance-receiver-documents" */
              '@/router/routes/admin/finance/receiver/AdminFinanceReceiverDocuments.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
        ],
      },
      {
        path: 'overview',
        name: 'admin-finance-overview',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-admin-finance-general" */
          '@/router/routes/admin/finance/AdminFinanceGeneral.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      }
    ]
  },
  {
    path: '/admin-reports',
    name: 'admin-reports',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-reports" */
      '@/router/routes/admin/AdminReports.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/saas',
    name: 'admin-saas',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-saas" */
      '@/router/routes/admin/saas/AdminSaas.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/saas/:id',
    name: 'admin-saas-details',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-details" */
      '@/router/routes/admin/saas/AdminSaasDetails.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/saas/:id/events',
    name: 'admin-saas-events',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-saas-events" */
      '@/router/routes/admin/saas/AdminSaasEvents.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/admin/saas/:id/subscription',
    name: 'admin-saas-subscription',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-admin-saas-subscription" */
      '@/router/routes/admin/saas/AdminSaasSubscription.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  {
    path: '/preview',
    name: 'preview',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-preview" */
      '@/router/routes/public/Result.vue'
    ),
    props: { preview: true },
    meta: { layout: 'toolbar', layoutProps: { withContainer: false } },
    beforeEnter: multiguard([authAndVerifiedMiddleware, adminOrSuperAdminMiddleware]),
  },
  // BECOMING ADMIN
  {
    path: '/activate-admin',
    name: 'activate-admin',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-activate-admin" */
      '@/router/routes/admin/ActivateAdmin.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  // -----------------------------------------
  // SUPER ADMIN
  // -----------------------------------------
  {
    path: '/super-admin-invites',
    name: 'super-admin-invites',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-super-admin-invites" */
      '@/router/routes/super-admin/SuperAdminInvites.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware, superAdminMiddleware]),
  },
];

const ownerRoutes = [
  // -----------------------------------------
  // OWNER
  // -----------------------------------------
  {
    path: '/owner-dashboard',
    name: 'owner-dashboard',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-dashboard" */
      '@/router/routes/owner/OwnerDashboard.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/owner-calendar',
    name: 'owner-calendar',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-calendar" */
      '@/router/routes/owner/OwnerCalendar.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/owner-spaces-events',
    name: 'owner-spaces-events',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-spaces-events" */
      '@/router/routes/owner/space/OwnerSpacesEvents.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/owner-unities',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-unity-wrapper" */
      '@/router/routes/owner/unity/OwnerUnityWrapper.vue'
    ),
    children: [
      {
        path: '',
        name: 'owner-unities',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-unities" */
          '@/router/routes/owner/unity/OwnerUnities.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'new',
        name: 'owner-unity-new',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-unity-new" */
          '@/router/routes/owner/unity/OwnerUnityNew.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'edit/:id',
        name: 'owner-unity-edit',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-unity-new" */
          '@/router/routes/owner/unity/OwnerUnityNew.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'unity/:id',
        name: 'owner-unity',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-unity" */
          '@/router/routes/owner/unity/OwnerUnity.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
    ],
  },
  {
    path: '/owner-spaces',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-space-wrapper" */
      '@/router/routes/owner/space/OwnerSpaceWrapper.vue'
    ),
    children: [
      {
        path: '',
        name: 'owner-spaces',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-spaces" */
          '@/router/routes/owner/space/OwnerSpaces.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'new',
        name: 'owner-space-new',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-space-new" */
          '@/router/routes/owner/space/OwnerSpaceNew.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'space/:id',
        name: 'owner-space',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-space" */
          '@/router/routes/owner/space/OwnerSpace.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'space/:id/finance',
        name: 'owner-space-finance',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-space-finance" */
          '@/router/routes/owner/space/OwnerSpaceFinance.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'space/:id/habitat-pass',
        name: 'owner-space-pass',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-space-pass" */
          '@/router/routes/owner/space/OwnerSpacePass.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
    ],
  },
  {
    path: '/owner-finance',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner-finance" */
      '@/router/routes/owner/finance/OwnerFinance.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
    children: [
      {
        path: 'overview',
        name: 'owner-finance-overview',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-finance-general" */
          '@/router/routes/owner/finance/OwnerFinanceGeneral.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'transactions',
        name: 'owner-finance-transactions',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-finance-transactions" */
          '@/router/routes/owner/saas/finance/OwnerSaasFinanceTransactions.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware, saasTeamMiddleware]),
      },
      {
        path: 'accounts',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-finance-accounts" */
          '@/router/routes/owner/finance/OwnerFinanceAccounts.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
        children: [
          {
            path: '',
            name: 'owner-finance-receivers',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receivers" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceivers.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId',
            name: 'owner-finance-receiver-details',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-details" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverDetails.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/banks',
            name: 'owner-finance-receiver-banks',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-banks" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverBanks.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/transactions',
            name: 'owner-finance-receiver-transactions',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-transactions" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverTransactions.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/history',
            name: 'owner-finance-receiver-history',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-history" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverHistory.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/transfer',
            name: 'owner-finance-receiver-transfer',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-transfer" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverTransfer.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/documents',
            name: 'owner-finance-receiver-documents',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-documents" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverDocuments.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: ':receiverId/rentability',
            name: 'owner-finance-receiver-rentability',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-finance-receiver-rentability" */
              '@/router/routes/owner/finance/receiver/OwnerFinanceReceiverRentability.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
        ],
      },
      {
        path: 'rentability',
        name: 'owner-finance-rentability',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-finance-rentability" */
          '@/router/routes/owner/finance/OwnerFinanceRentability.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
    ],
  },
  {
    path: '/chat-with-doctors',
    name: 'chat-with-doctors',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-chat-with-doctors" */
      '@/router/routes/owner/ChatWithDoctors.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/owner',
    redirect: '/owner-dashboard',
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-owner" */
      '@/router/routes/owner/Owner.vue'
    ),
    children: [
      {
        path: 'saas',
        redirect: '/owner-dashboard',
        name: 'owner-saas',
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware, saasTeamMiddleware]),
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-owner-saas" */
          '@/router/routes/owner/saas/Saas.vue'
        ),
        children: [
          {
            path: 'dashboard',
            name: 'owner-saas-dashboard',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-dashboard" */
              '@/router/routes/owner/saas/OwnerSaasDashboard.vue'
            ),
            meta: { layout: 'sidebar', layoutProps: { fullHeight: true } },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'calendar',
            name: 'owner-saas-calendar',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-calendar" */
              '@/router/routes/owner/saas/OwnerSaasCalendar.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware])
          },
          {
            path: 'services',
            name: 'owner-saas-services',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-services" */
              '@/router/routes/owner/saas/OwnerSaasServices.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware])
          },
          {
            path: 'subscription',
            name: 'owner-saas-subscription',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-subscription" */
              '@/router/routes/owner/saas/subscription/OwnerSaasSubscription.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware])
          },
          {
            path: 'events',
            name: 'owner-saas-events',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-events" */
              '@/router/routes/owner/saas/OwnerSaasEvents.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware])
          },
          {
            path: 'unities',
            name: 'owner-saas-unities',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-unities" */
              '@/router/routes/owner/saas/unities/OwnerSaasUnities.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
            children: [
              {
                path: '',
                name: 'owner-saas-unities-list',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                /* webpackChunkName: "route-owner-saas-unities-list" */
                '@/router/routes/owner/saas/unities/OwnerSaasUnitiesList.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
              {
                path: 'new',
                name: 'owner-saas-unity-new',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                  /* webpackChunkName: "route-owner-saas-unity-new" */
                  '@/router/routes/owner/saas/unities/OwnerSaasUnityNew.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
              {
                path: 'edit/:id',
                name: 'owner-saas-unity-edit',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                  /* webpackChunkName: "route-owner-saas-unity-new" */
                  '@/router/routes/owner/saas/unities/OwnerSaasUnityNew.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
              {
                path: ':id',
                name: 'owner-saas-unity',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                  /* webpackChunkName: "route-owner-saas-unity" */
                  '@/router/routes/owner/saas/unities/OwnerSaasUnity.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
            ],
          },
          {
            path: 'spaces',
            name: 'owner-saas-spaces',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-spaces" */
              '@/router/routes/owner/saas/spaces/OwnerSaasSpaces.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
            children: [
              {
                path: ':id',
                name: 'owner-saas-space',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                /* webpackChunkName: "route-owner-saas-space" */
                '@/router/routes/owner/saas/spaces/OwnerSaasSpace.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
              {
                path: ':id/finance',
                name: 'owner-saas-space-finance',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                /* webpackChunkName: "route-owner-saas-space-finance" */
                '@/router/routes/owner/saas/spaces/OwnerSaasSpaceFinance.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
              {
                path: ':id/pass',
                name: 'owner-saas-space-pass',
                alias: [],
                component: () => import(
                  /* webpackPrefetch: true */
                /* webpackChunkName: "route-owner-saas-space-pass" */
                '@/router/routes/owner/saas/spaces/OwnerSaasSpacePass.vue'
                ),
                meta: { layout: 'sidebar' },
                beforeEnter: multiguard([authAndVerifiedMiddleware]),
              },
            ],
          },
          {
            path: 'users',
            name: 'owner-saas-users',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-users" */
              '@/router/routes/owner/saas/OwnerSaasUsers.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/user/product',
            name: 'owner-saas-user-product',
            alias: [],
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-product" */
              '@/router/routes/owner/saas/users/SaasUserProduct.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/add-user',
            name: 'owner-saas-add-user',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-add-user" */
              '@/router/routes/owner/saas/OwnerSaasAddUser.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id',
            name: 'owner-saas-user-details',
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-details" */
              '@/router/routes/owner/saas/users/SaasUserDetails.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id/profile',
            name: 'owner-saas-user-profile',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-profile" */
              '@/router/routes/owner/saas/users/SaasUserProfile.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id/products',
            name: 'owner-saas-user-products',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-products" */
              '@/router/routes/owner/saas/users/SaasUserProducts.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id/transactions',
            name: 'owner-saas-user-transactions',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-transactions" */
              '@/router/routes/owner/saas/users/SaasUserTransactions.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id/events',
            name: 'owner-saas-user-events',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-events" */
              '@/router/routes/owner/saas/users/SaasUserEvents.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'users/:id/doctor-calendar',
            name: 'saas-user-doctor-calendar',
            alias: [],
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-user-calendar" */
              '@/router/routes/owner/saas/users/SaasUserDoctorCalendar.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'team',
            name: 'owner-saas-team',
            component: () => import(
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-team" */
              '@/router/routes/owner/saas/OwnerSaasTeam.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          },
          {
            path: 'team/:id',
            name: 'owner-saas-team-details',
            component: () => import (
              /* webpackPrefetch: true */
              /* webpackChunkName: "route-owner-saas-team-details" */
              '@/router/routes/owner/saas/team/SaasTeamDetails.vue'
            ),
            meta: { layout: 'sidebar' },
            beforeEnter: multiguard([authAndVerifiedMiddleware]),
          }
        ]
      },
    ],
  },
];

const managerRoutes = [
  {
    path: '/manager',
    redirect: '/doctor-dashboard',
    name: 'manager-index',
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-manager-index" */
      '@/router/routes/manager/Index.vue'
    ),
    children: [
      {
        path: 'dashboard',
        name: 'manager-dashboard',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-manager-dashboard" */
          '@/router/routes/manager/ManagerDashboard.vue'
        ),
        meta: { layout: 'sidebar', layoutProps: { fullHeight: true } },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'calendar',
        name: 'manager-calendar',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-manager-calendar" */
          '@/router/routes/manager/ManagerCalendar.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware])
      },
      {
        path: 'events',
        name: 'manager-events',
        alias: [],
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-manager-events" */
          '@/router/routes/manager/ManagerEvents.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware])
      },
      {
        path: 'users',
        name: 'manager-users',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-manager-users" */
          '@/router/routes/manager/ManagerUsers.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
    ]
  },
];

const extraRoutes = [
  // -----------------------------------------
  // Redirects to old site
  // -----------------------------------------
  {
    path: '/blog',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/');
    },
  },
  {
    path: '/como-montar-um-consultorio-de-fisioterapia',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/como-montar-um-consultorio-de-fisioterapia');
    },
  },
  {
    path: '/escritorios-compartilhados-e-a-estrutura-completa-que-voce-procura',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/escritorios-compartilhados-e-a-estrutura-completa-que-voce-procura');
    },
  },
  {
    path: '/unidades',
    redirect: '/',
  },
  {
    path: '/medicos',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/medicos/');
    },
  },
  {
    path: '/psicologos',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/psicologos/');
    },
  },
  {
    path: '/contato',
    beforeEnter() {
      window.location.replace('https://blog.habitatconsultorios.com.br/contato/');
    },
  },
];

const doctorRoutes = [
  // -----------------------------------------
  // DOCTOR
  // -----------------------------------------
  {
    path: '/doctor-validate',
    name: 'doctor-validate',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-validate" */
      '@/router/routes/doctor/DoctorValidate.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },{
    path: '/doctor/dashboard',
    name: 'doctor-dashboard',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-dashboard" */
      '@/router/routes/doctor/DoctorDashboard.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-calendar',
    name: 'doctor-calendar',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-calendar" */
      '@/router/routes/doctor/DoctorCalendar.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-transcriptions',
    name: 'doctor-transcriptions',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-transcriptions" */
      '@/router/routes/doctor/DoctorTranscriptions.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor/marketplace',
    name: 'doctor/marketplace',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-marketplace" */
      '@/router/routes/doctor/DoctorMarketplace.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/chat-with-owners',
    name: 'chat-with-owners',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-chat-with-owners" */
      '@/router/routes/doctor/ChatWithOwners.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-products',
    name: 'doctor-products',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-products" */
      '@/router/routes/doctor/DoctorProducts.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-finance',
    name: 'doctor-finance',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-finance" */
      '@/router/routes/doctor/finance/DoctorFinance.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-payment-debit',
    name: 'doctor-payment-debit',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-payment-debit" */
      '@/router/routes/doctor/finance/DoctorPaymentDebit.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/event-review',
    name: 'event-review',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-event-review" */
      '@/router/routes/doctor/EventReview.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-events',
    name: 'doctor-events',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-events" */
      '@/router/routes/doctor/DoctorEvents.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/doctor-services',
    name: 'doctor-services',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-doctor-services" */
      '@/router/routes/doctor/DoctorServices.vue'
    ),
    meta: { layout:'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
];

const commonRoutes = [
  // -----------------------------------------
  // DOCTOR AND OWNER
  // -----------------------------------------
  {
    path: '/profile',
    name: 'profile',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-profile" */
      '@/router/routes/common/Profile.vue'
    ),
    meta: {
      layout: 'sidebar',
      title: 'Perfil',
    },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/info',
    name: 'profile-info',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-profile-info" */
      '@/router/routes/common/ProfileInfo.vue'
    ),
    meta: {
      layout: 'sidebar',
      title: 'Perfil',
      backTo: '/profile',
    },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/services',
    name: 'profile-services',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-profile-services" */
      '@/router/routes/common/ProfileServices.vue'
    ),
    meta: {
      layout: 'sidebar',
      title: 'Serviços',
      backTo: '/profile',
    },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/specs',
    name: 'profile-specs',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-profile-specs" */
      '@/router/routes/common/ProfileSpecs.vue'
    ),
    meta: {
      layout: 'sidebar',
      title: 'Especialidades',
      backTo: '/profile',
    },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/helpdesk',
    name: 'helpdesk',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-helpdesk" */
      '@/router/routes/common/Helpdesk.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/settings',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-settings" */
      '@/router/routes/common/settings/Settings.vue'
    ),
    meta: { layout: 'sidebar' },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
    children: [
      {
        path: 'data-share-list',
        name: 'settings-data-share-list',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-settings-data-share-list" */
          '@/router/routes/common/settings/DataShareList.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
      {
        path: 'terms-of-use-list',
        name: 'settings-terms-of-use-list',
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "route-settings-terms-of-use-list" */
          '@/router/routes/common/settings/TermsOfUseList.vue'
        ),
        meta: { layout: 'sidebar' },
        beforeEnter: multiguard([authAndVerifiedMiddleware]),
      },
    ],
  },
  {
    path: '/saas/access/request',
    name: 'saas-access-request',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-saas-access-request" */
      '@/router/routes/public/SaasAccessRequest.vue'
    ),
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true, hideButtons: true } },
  },
];

const publicRoutes = [
  // -----------------------------------------
  // PUBLIC
  // -----------------------------------------
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-welcome" */
      '@/router/routes/public/Welcome.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: false, transparentToolbar: true } },
  },
  {
    path: '/',
    name: 'home',
    alias: ['/index', '/home', '/index.html'],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-home" */
      '@/router/routes/public/Home.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: false, transparentToolbar: true } },
  },
  {
    path: '/saas',
    name: 'home-saas',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-home-saas" */
      '@/router/routes/public/HomeSaas.vue'
    ),
    meta: {
      layout: 'toolbar',
      layoutProps: {
        withContainer: false,
        transparentToolbar: true,
        isSaasHome: true,
      },
    },
  },
  {
    path: '/saas/prices/',
    name: 'home-saas-prices',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-home-saas-prices" */
      '@/router/routes/public/SaasPrices.vue'
    ),
    meta: {
      layout: 'toolbar',
      layoutProps: {
        withContainer: false,
        transparentToolbar: true,
        isSaasHome: true,
        isSaasPrices: true,
      },
    },
  },
  {
    path: '/saas/sign-up/',
    name: 'saas-sign-up',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-saas-sign-up" */
      '@/router/routes/public/SaasSignUp.vue'
    ),
    meta: {
      layout: 'toolbar',
      layoutProps: {
        withContainer: false,
        transparentToolbar: true,
        isSaasHome: true,
        isSaasPrices: true,
      },
    },
  },
  {
    path: '/saas/info',
    name: 'saas-landing-page-info',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-saas-page-info" */
      '@/router/routes/public/SaasLandingPage.vue'
    ),
    meta: {},
  },
  {
    path: '/saas/thank-you',
    name: 'saas-thank-you',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-saas-thank-you" */
      '@/router/routes/public/SaasPostContact.vue'
    ),
    meta: {},
  },
  {
    path: '/saas/info/thank-you',
    name: 'saas-info-thank-you',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-saas-info-thank-you" */
      '@/router/routes/public/SaasLandingPostContact.vue'
    ),
    meta: {},
  },
  {
    path: '/search',
    name: 'search',
    alias: ['/search'],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-home" */
      '@/router/routes/public/Home.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: false, transparentToolbar: true } },
  },
  {
    path: '/results',
    name: 'results',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-results" */
      '@/router/routes/public/Results.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  {
    path: '/result',
    name: 'result',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-result" */
      '@/router/routes/public/Result.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: false } },
  },
  {
    path: '/schedule-visit',
    name: 'schedule-visit',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-schedule-visit" */
      '@/router/routes/doctor/ScheduleVisit.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  {
    path: '/schedule-visit-confirmation',
    name: 'schedule-visit-confirmation',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-schedule-visit-confirmation" */
      '@/router/routes/doctor/ScheduleVisitConfirmation.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/checkout',
    name: 'checkout',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-checkout" */
      '@/router/routes/doctor/buy-products/Checkout.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
    beforeEnter: multiguard([authAndVerifiedMiddleware]),
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-email-verification" */
      '@/router/routes/public/EmailVerification.vue'
    ),
    meta: { layout: 'empty', layoutProps: {} },
    // beforeEnter: multiguard([authButNotVerifiedMiddleware]),
  },
  {
    path: '/set-password',
    name: 'set-password',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-set-password" */
      '@/router/routes/public/SetPassword.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true, hideButtons: true } },
    // beforeEnter: multiguard([authButNotVerifiedMiddleware]),
  },
  {
    path: '/invoice',
    name: 'invoice',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-invoice" */
      '@/router/routes/doctor/buy-products/Invoice.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  {
    path: '/survey',
    name: 'survey',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-survey" */
      '@/router/routes/public/Survey.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  // -----------------------------------------
  // NOT CONNECTED
  // -----------------------------------------
  {
    path: '/login',
    name: 'login',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-login" */
      '@/router/routes/public/Login.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
    beforeEnter: multiguard([notAuthOrNotVerifiedMiddleware]),
  },
  {
    path: '/signup',
    name: 'signup',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-signup" */
      '@/router/routes/public/SignUp.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
    beforeEnter: multiguard([notAuthOrNotVerifiedMiddleware]),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-privacy-policy" */
      '@/router/routes/public/PrivacyPolicy.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-terms-of-use" */
      '@/router/routes/public/TermsOfUse.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
  {
    path: '/payment-voucher',
    name: 'payment-voucher',
    alias: [],
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "route-payment-voucher" */
      '@/router/routes/public/PaymentVoucher.vue'
    ),
    meta: { layout: 'toolbar', layoutProps: { withContainer: true } },
  },
];

const router = new VueRouter({
  mode: 'history',

  routes: [
    ...(IS_APP ? [] : adminRoutes),
    ...(IS_APP ? [] : ownerRoutes),
    ...(IS_APP ? [] : extraRoutes),
    ...doctorRoutes,
    ...commonRoutes,
    ...publicRoutes,
    ...managerRoutes,
    // {
    //   path: '/landing-page',
    //   name: 'landing-page',
    //   alias: ['/landing'],
    //   component: () => import(
    //     /* webpackPrefetch: true */
    //     /* webpackChunkName: "route-landing-page" */
    //     '@/router/routes/public/LandingPage.vue'
    //   ),
    //   meta: { layout: 'default', layoutProps: {} },
    // },
    // {
    //   path: '/help',
    //   name: 'help',
    //   alias: ['/ajuda'],
    //   component: () => import(
    //     /* webpackPrefetch: true */
    //     /* webpackChunkName: "route-help" */
    //     '@/router/routes/public/Help.vue'
    //   ),
    //   meta: { layout: 'default' },
    // },
    // {


    // -----------------------------------------
    // FALLBACK: Must be the last route (wildcard)
    // -----------------------------------------
    {
      path: '*',
      name: 'not-found',
      component: () => import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "route-not-found" */
        '@/router/routes/public/NotFound.vue'
      ),
      meta: { layout: 'empty' },
    },
  ],

  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth',
      })
    }
    if (savedPosition) {
      // for popstate only
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
});

let observingAuth = false;

router.beforeEach(async (to, from, next) => {
  if (!IS_APP) {
    NProgress.start()
    NProgress.set(0.4)
  }

  await store.dispatch('app/loadConfig');

  const updatedRoute = {};

  await AuthService.isInitialized();

  const user = await AuthService.currentUser();
  const config = await store.dispatch('app/loadConfig');
  const readonlyInfo = store.getters['user/GET_READONLY_INFO'];

  if (to.name === 'home' && user && IS_APP) {
    updatedRoute.name = 'doctor-dashboard';
  }

  if (to.path === '//localhost/' && IS_APP) {
    updatedRoute.name = 'home';
  }

  const readonlyInfoGuard = (info) => {
    const newRoute = {};

    if (info?.error === 'error.not.saas.user') {
      if (to.name !== 'saas-access-request') {
        newRoute.name = 'saas-access-request';
      }
    } else if (info?.saasRole) {
      if (to.name === 'saas-access-request') {
        newRoute.name = 'home';
      } else if (!user?.emailVerified && to.name !== 'login' && to.name !== 'signup' && to.name !== 'email-verification') {
        newRoute.name = 'login';
      }
    }

    if (info?.mustSetPassword && to.name !== 'set-password') {
      newRoute.name = 'set-password';
      newRoute.query = {
        email: user.email,
      };
    }

    return newRoute;
  };

  const newRoute = readonlyInfoGuard(readonlyInfo);

  if (newRoute.name) {
    updatedRoute.name = newRoute.name;
    updatedRoute.query = newRoute.query;
  }

  document.title = config.saasName || DEFAULT_PAGE_TITLE;
  useFavicon(config.saasImage || '/favicon.ico');
  // Reset theme color
  document.querySelector('meta[name="theme-color"]').setAttribute('content', '#8CC63F');

  if (!observingAuth) {
    observingAuth = true;

    AuthService.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        const email = user.email;

        Analytics.setUserProperties({ userId, email });
        store.dispatch('settings/loadPublicSystem');

        const waitSignUp = async () => {
          const isSigningUp = store.getters['user/GET_IS_SIGNING_UP'];

          if (isSigningUp) {
            // Wait 1 second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            return waitSignUp();
          }
        }

        await waitSignUp();

        // signed in

        firestoreDb
          .collection(collections.users)
          .doc(userId)
          .get()
          .then(handleDoc)
          .then(async (userInfo) => {
            const isLoggingIn = store.getters['user/GET_IS_LOGGING_IN'];
            // before multiguard
            store.dispatch('user/setInitialData', { user, userInfo})
            // we must wait this one because it is used in middlewares

            try {
              await Promise.all([
                store.dispatch('user/fetchAcceptedTermsInfo'),
                store.dispatch('user/fetchReadonlyInfo')
                  .catch((error) => {
                    if (isLoggingIn) {
                      return;
                    }

                    if (error === 'error.saas.user.not.found') {
                      Vue.toasted.global.custom_error({ message: this.$t('error.userNotFound') })
                      store.dispatch('user/signout');
                    }
                  }),
              ]);

              store.dispatch('user/loadChatCounts');
              store.dispatch('validateUser/loadDoctorValidations');

              if (isIOS()) {
                getATTStatus().then((status) => {
                  store.dispatch('user/updateATT', status);
                });
              }

              const readonlyInfo = store.getters['user/GET_READONLY_INFO'];
              const newRoute = readonlyInfoGuard(readonlyInfo);

              if (newRoute.name) {
                updatedRoute.name = newRoute.name;
                updatedRoute.query = newRoute.query;
              }

              console.log('up router', updatedRoute);

              next(updatedRoute);
            } catch (err) {
              console.error(`Could not fetch readonly info`, err);
              throw err;
            }
          }).catch((err) => {
            console.error(`Could not fetch user with uid ${user.uid}`);
            throw err;
          })
      } else {
        console.log('up router 2', updatedRoute);
        // not signed in
        next(updatedRoute);
      }
    });
  } else {
    console.log('up router 3', updatedRoute);
    next(updatedRoute);
  }
});

router.afterEach((to) => {
  document.body.setAttribute('data-route', to.name);
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length;
  const fromDepth = from.path.split('/').length;
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
});

router.afterEach(() => {
  // NOTE analytics must be placed here: otherwise we register paths before navigation guards (redirections etc)
  Analytics.logEvent(events.page_view, { type: 'internal' });
  if (!IS_APP) {
    NProgress.done();
  }
})

export default router;
